@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap');

/*
https://bootstrap.build/app
*/

/* 
Show on large, not on small:
d-none d-lg-block ``

Show on small, not on large:
d-block d-sm-none 

*/

html {
  position: relative;
  min-height: 100%;
}

:root {
  --earsonly-orange: #ff6d2d;
  --earsonly-orange-light: #fdc8ac;
  --earsonly-orange-dark: #c0552c;
  --earsonly-gray: #5b6e7f;
  --earsonly-gray-dark: #36424d;
  --earsonly-white: #ffffff;
}

.btn-primary {
  background-color: var(--earsonly-orange);
  border-color: var(--earsonly-orange);
  color: var(--earsonly-white);
}

.btn-primary:hover {
  border-color: var(--earsonly-orange-dark);
  color: var(--earsonly-white);
  background-color: var(--earsonly-orange-dark);
}

.btn-primary:active {
  --bs-btn-active-bg: var(--earsonly-orange-dark);
  --bs-btn-active-border-color: var(--earsonly-orange-dark);
  --bs-btn-active-color: var(var(--earsonly-white))
}

.btn-primary:disabled {
  background-color: var(--earsonly-orange-dark);
  border-color: var(--earsonly-orange-dark);
}

.btn-outline-primary {
  border: none;
  --bs-btn-color: var(--earsonly-orange);
}

.btn-outline-primary:hover {
  border-color: var(--earsonly-orange);
  color: var(--earsonly-white);
  background-color: var(--earsonly-orange);
}

.btn-outline-primary:focus {
  border-color: var(--earsonly-orange);
  color: var(--earsonly-white);
  background-color: var(--earsonly-orange);

}

.btn-outline-primary:active {
  --bs-btn-active-bg: var(--earsonly-orange-dark);
  --bs-btn-active-border-color: var(--earsonly-orange-dark);
  --bs-btn-active-color: var(var(--earsonly-white))
}

.btn-secondary {
  background-color: var(--earsonly-gray);
  border-color: var(--earsonly-gray);
}

.btn-secondary:hover {
  border-color: var(--earsonly-gray-dark);
  color: var(--earsonly-white);
  background-color: var(--earsonly-gray-dark);
}

.btn-outline-secondary {
  border-color: var(--earsonly-gray);
  color: var(--earsonly-gray);
}

.btn-outline-secondary:hover {
  border-color: var(--earsonly-gray);
  color: var(--earsonly-white);
  background-color: var(--earsonly-gray);
}

.btn-group {
  border: 1px solid var(--earsonly-orange);
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background-color: var(--earsonly-orange);
}

.list-group-item.disabled {
  background-color: #e8e8e8;
}

/** Colors for the progress bar*/
.bg-primary {
  background-color: var(--earsonly-orange) !important;
  font-weight: bold;
  color: black;
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
}

.progress {
  height: 2em;
  font-size: 1.5em;
}

div {
  font-family: 'Montserrat', sans-serif;
}

:any-link {
  text-decoration: none;
  color: var(--earsonly-white);
}

:any-link:hover {
  color: var(--earsonly-orange);
}

:any-link:active {
  background-color: var(--earsonly-gray-dark);
}

.dropdown-item {
  color: var(--earsonly-gray);

}

.dropdown-item:active {
  background-color: var(--earsonly-white);

}

.termsandconditions {
  color: var(--earsonly-orange)
}

.termsandconditions:hover {
  color: var(--earsonly-orange-dark)
}

.link-as-button {
  font-size: 1rem;
  padding: 0.375rem 0.75rem;
  align-items: flex-start;
  appearance: button;
  text-decoration: none;
  color: var(--earsonly-orange);
  border-radius: 0.375rem;
}

.link-as-button:hover {
  background-color: var(--earsonly-orange);
  color: var(--earsonly-white)
}

h1 {
  text-align: center;
  padding-bottom: 0.2em;
}

p {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-align: center;
  font-size: 1.5em;
  color: #2f4f4f;
  font-weight: bold;
}

Form {
  font-weight: normal;
}

h3 {
  padding-top: 2em;
}

.sonner-icon {
  p {
    margin-left: -.65em;
    text-align: center;
    font-size: 1.25em;
    color: black;
    font-weight: bold;
  }
}

.card-header>p {
  color: var(--earsonly-orange)
}

.paddingTopBottom {
  padding-top: 5em;
  padding-bottom: 7em;
}

.sales-filter {
  padding-top: 1em;
  padding-bottom: 1em;
  align-items: center;
}

.no-bottom-padding {
  padding-bottom: 0;
}

.no-right-padding {
  padding-right: 0;
}

.ButtonGroup {
  padding-top: 5em;
}

.signout-button {
  margin-left: 1em;
  transition: all .5s;
  border: none;
}

.signout-button:hover::after {
  content: 'Logout';
}

.order-logo {
  max-height: 25px;
  max-width: 25px;
}


.middle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.loginscreen {
  @media screen and (max-width: 576px) {
    height: 100vh;
  }

  @media screen and (min-width: 576px) {
    height: 100vh;
  }
}

.center {
  text-align: center;
}

.right {
  float: right;
  text-align: right;
}

.left {
  float: left;
  text-align: left;
}

.red {
  background-color: rgba(255, 0, 0, 0.956);
}

.green {
  background-color: rgba(0, 128, 0, 0.268);
}

.blue {
  background-color: rgba(0, 0, 255, 0.214);
}

.marked {
  background-color: #ff56221e;
}

div a {
  margin: 0.1em 0.1em 0.1em 0.1em
}


.full-border {
  border: 1px solid #c9c9c9
}

.right-border {
  border-right: 1px solid #c9c9c9;
}

.bottom-border {
  border-bottom: 1px solid #c9c9c9;
}

.grouped-vertical-buttons {
  margin-bottom: 0.2em;
}

.filter {
  border-radius: 20px;
}

svg {
  font-size: 1.4em;
  margin-right: 0.3em;
  margin-left: 0.3em;
}

.backbutton {
  font-size: 1em;
  margin-left: 0;
}

.padding-bottom {
  padding-bottom: 1em;
}

.text {
  align-items: right;
}

h1>svg {
  font-size: 1em;
  position: relative;
  bottom: 0.4em;
  right: 0.4em;
}

.pdf-modal {
  height: 80vh;
}

.card {
  cursor: pointer;
}

.image-preview {
  align-content: center;
}


.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f5f5f5;
  padding-left: 1em;

  a {
    color: darkslategrey;
  }
}

.footer-right {
  padding-right: 1em;
  float: right;
  text-align: right;
}

.tenant-cards {
  p {
    color: var(--earsonly-gray);
  }
}

.admin-card {
  p {
    color: var(--earsonly-orange-dark)
  }
}

.navbar-brand {
  cursor: pointer;
  color: var(--earsonly-gray);

  .nav-link {
    color: var(--earsonly-gray);
  }
}

.nav-link {
  color: var(--earsonly-gray);
}

.tttt {
  background-color: lightblue !important;

  span::after {
    content: ' -TEST.ENV'
  }

  .dropdown-toggle {
    background-color: lightblue !important;

  }

  .bg-light {
    background-color: lightblue !important;

  }
}

.download-pdf-button {
  padding-top: 1em;

  a {
    border-radius: .5em;
    padding: .5em;
    background-color: var(--earsonly-orange);
    color: var(--earsonly-white)
  }
}

.download-button {
  padding-bottom: 10px;
}

.copy:hover {
  cursor: copy;
  color: rgb(63, 202, 248);
}


.tracker {
  font-size: 2em;
}

.tracker-mobile {
  font-size: 1.2em;
}

.tracker-current {
  color: var(--earsonly-orange)
}

.tracker-passed {
  color: var(--earsonly-gray)
}

.align-selectors {
  align-self: center;
}


.notsignedin {
  border-radius: 1em;
  padding: .5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.brandconfig-link {
  color: var(--earsonly-orange);

  small {
    color: black;
  }
}

.product-selection {
  padding-bottom: 1em;
  .titles {
    font-weight: bold;
  }

  .name {
    font-weight: bold;
  }

  .border-right {
    border-right: black dashed 1px;
  }
}

.confirm-order-link{
  color:black
}